.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  background-color: white;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
  padding: 55px;
  max-width: 600px;
}

.text-wrapper {
  margin-bottom: 25px;
}

.buttons-wrapper {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
