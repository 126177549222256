@import "src/style/media.scss";

.rw-header {
  border-bottom: 1px solid #f2f2f2;

  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    padding: 0.5rem 1rem;
    animation: 0.5s transition;

    @include media-breakpoint(md) {
      padding: 1rem 1rem;
    }

    @keyframes transition {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .back-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      span {
        display: none;

        @include media-breakpoint(md) {
          display: block;
          margin-left: 0.25rem;
        }
      }
    }

    .runway-logo {
      line-height: 1;

      img {
        width: 100px;
        height: auto;

        @include media-breakpoint(md) {
          width: 110px;
        }
      }
    }
  }

  .back-button-placeholder {
    margin-right: 4.5%;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: rgba(#aac4f9, 0.2);
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: #aac4f9;
  }
}
