@import "src/style/media.scss";

body {
  @media (max-width: 870px) {
    background-color: white !important;
  }
}

.checkout-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 70px 0px 70px;
  margin-bottom: 50px;
  @include response(md) {
    flex-direction: column;
    padding: 10px;
  }
  gap: 40px;
  .aligned-title {
    text-align: center;
  }
  .checkout-card {
    @media (max-width: 870px) {
      padding: 15px !important;
      box-shadow: none !important;
      background-color: white !important;
    }
  }
  .left-checkout-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    width: 50%;
    @include response(md) {
      width: 100%;
    }
    .shipping-address-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    .shipping-address {
      text-align: end;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .right-checkout-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include response(md) {
      width: 100%;
    }
    .crossed-out-visit-fee {
      text-decoration: line-through;
      text-align: end;
      padding-right: 10px;
      color: gray;
    }
    .highlighted-fee {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 15px 10px;
    }
    .shipping-fee {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 0px 10px;
    }
    .treatment-fee {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 10px 30px;
      p {
        font-weight: 700;
      }
    }
    .order-total-wrapper {
      display: flex;
      text-align: end;
      justify-content: space-between;
      padding-bottom: 40px;
    }
    .banner-wrapper {
      padding: 10px;
    }
    .promocode-link-wrapper {
      text-align: center;
    }
    .promocode-input-wrapper {
      width: 100%;
      position: relative;
      display: inline-block;
      input {
        padding-right: 30px;
      }
      a {
        position: absolute;
        top: 23px;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
  .pay-button-container {
    text-align: center;
    margin-top: 10px;
  }
  .error-text {
    color: #ff545499;
  }
  .payment-agreement-notice {
    font-size: small;
    color: #565656;
    padding: 20px 15px 0px 15px;
  }
}
