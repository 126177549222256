.pharmacy-find-block{
  overflow-y: auto;
  width: 100%;
  max-height: 306px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  margin-top: 40px;

  &::-webkit-scrollbar {
    width: 3px;               /* width scrollbar */
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: #E0E0E0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4F4F4F;
    border-radius: 100px;
  }
}
