@import "src/style/media.scss";
.sign-up-page {
  width: 100%;
}

.form-wrapper {
  padding-top: 48px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: 1s transition;
  @include response(md) {
    width: 100vw !important;
    height: auto !important;
    padding-top: 0;
  }
  @keyframes transition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .sign-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    .sign-up-content {
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 50%;
      @include response(md) {
        width: 100%;
        margin-left: 0;
        padding: 10px;
      }
      label {
        text-align: center;
        font-size: 20px;
      }
      .sign-up {
        font-size: 36px;
        margin-bottom: 47px;
        margin-top: 30px;
        @include response(md) {
          font-size: 24px;
        }
      }

      @include response(md) {
        margin: 8% 0% 10% 0%;
      }

      .signup-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-evenly;

        .form {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 100%;
          margin: auto;
          justify-content: space-evenly;
          @include response(md) {
            width: 100%;
          }
          @include response(sm) {
            padding: 0 10px 0 10px;
          }

          .input-names {
            display: flex;
            justify-content: space-between;
            @include response(md) {
              display: flex;
              flex-direction: column;
              width: 100%;
            }
          }

          .agreement {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            margin-left: -16px;

            .terms {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              div {
                margin-bottom: 0;
              }
            }
          }

          .button-div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 36px;
            margin-bottom: 30px;
            @include response(md) {
              width: 100%;
            }
          }

          .submit-button {
            cursor: pointer;
            background: #aac4f9;
            border-radius: 58px;
            border: none;
            font-family: "Gustavo";
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 25rem;
            height: 3rem;
            font-size: 1.5rem;
            color: #393939;
            transition: 0.5s;

            &:hover {
              transform: scale(1.03);
            }
          }

          .signup-input-names {
            width: 45%;
            min-width: 10rem;
            height: 3.5rem;
            border: none;
            border-bottom: 1px solid black;

            outline: none;
            font-size: 1.5rem;
            font-family: "Gustavo";
            margin-bottom: 20px;
            @include response(md) {
              width: 100%;
              font-size: 20px;
            }
          }

          .signup-input {
            height: 3.5rem;
            border: none;
            border-bottom: 1px solid black;

            outline: none;
            font-size: 1.5rem;
            font-family: "Gustavo";
            margin-bottom: 20px;

            &.password {
              border-bottom: none;
            }
            @include response(md) {
              width: 100%;
            }
            @include response(sm) {
              font-size: 20px;
            }
          }

          input::placeholder {
            color: rgba(57, 57, 57, 0.5);
          }
        }
      }
    }
  }
}

.mobile-checkboxes {
  display: flex;
  margin: 1rem 0;
}

.error-message-signup {
  visibility: hidden;
  display: flex;
  width: 100%;
  min-height: 40px;
  background-color: inherit;
  align-items: center;
  justify-content: flex-start;
  margin: auto;

  &.active {
    visibility: visible;
  }

  .message-content-signup {
    width: 100%;
    text-align: left;
    color: #eb5757;
    @include response(md) {
      width: 100%;
    }
  }
}

.terms-label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.container-helpform {
  display: none;
  border: 3px solid rgba(170, 196, 249, 0.4);
  border-radius: 20px;
  position: relative;

  .list-items {
    padding: 15px 0 0 20px;
  }

  &.active {
    display: flex;
    position: absolute;
    top: 91px;
    z-index: 1000;
    opacity: 1;
    background-color: #f6f9ff;
    width: 100%;
    height: 153px;
  }
  &.errorExist {
    border: 3px solid #ea5757;
  }
}

.container-text {
  padding: 22px 0 22px 36px;

  ul {
    margin: 0;
    padding: 0;
  }
}

.top-bot {
  position: absolute;
  top: -17px;
  right: 35px;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent rgba(170, 196, 249, 0.4) transparent;

  &.errorExist {
    border-color: transparent transparent #ea5757 transparent;
  }
}

.password-signup-footer {
  height: 10%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.password-signup-footer-input {
  border-bottom: 1px solid #393939;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 38px;
}

.img-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .imgItemInfo {
    cursor: pointer;
    height: 25px;
    margin-right: 16px;
  }

  .imgItemEye {
    height: 10px;

    cursor: pointer;
    vertical-align: text-top;
  }
}

.signup-label {
  width: 100%;
  font-weight: bold;
  padding-left: 4px;
  padding-bottom: 18px;
  display: block;
  font-size: 12px;
}

.login-link-container {
  text-align: center;
}
