.choose-option {
  margin-bottom: 0px;
  margin-top: 32px;
}

.email-message-text {
  color: #3f757a;
  text-decoration: underline;
}

.sync-feedback {
  line-height: 24px;
  margin-top: 1rem;
}
