@import "src/style/media";
textarea {
  resize: none;
}

textarea::-webkit-resizer {
  display: none;
}

.custom-input {
  border-bottom: 1px solid #393939 !important;
  height: 50px;
}

.account {
  * {
    box-sizing: border-box;
  }
  overflow-y: hidden;
  padding: 0 120px 100px 120px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  @include response(lg) {
    padding: 0 50px 30px 50px;
  }
  @include response(md) {
    padding: 0 50px 30px 50px;
  }
  @include response(sm) {
    padding: 0 10px 15px 10px;
  }
  .billing-card {
    padding: 32px;
    @include response(sm) {
      padding: 10px;
    }
  }

  &-header-form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-payment-manager {
      @extend .account-header-form;
      @include response(sm) {
        padding: 17px 20px 5px 24px;
      }
    }

    &-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #aac4f9;
      box-sizing: border-box;
      border-radius: 58px;
      width: 76px;
      height: 32px;
    }

    &-mdbutton {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #aac4f9;
      box-sizing: border-box;
      border-radius: 58px;
      width: 200px;
      height: 32px;
    }

    &-xlbutton {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #aac4f9;
      box-sizing: border-box;
      border-radius: 58px;
      width: 250px;
      height: 32px;
    }

    .back-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 4.5rem;
      margin-left: 2.5%;
      cursor: pointer;

      img {
        height: 15px;
      }

      span {
        @include response(sm) {
          display: none;
        }
      }
    }
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  &-billing {
    &-form {
      display: flex;
      flex-direction: column;
      gap: 17px;
      &-subtitle {
        h4 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 42px;
        }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 26px;
        }
      }
      &-inputs {
        display: flex;
        gap: 4%;
        @include response(sm) {
          flex-direction: column;
          gap: 17px;
        }
        & label:first-child {
          width: 48%;
          @include response(sm) {
            width: 100%;
          }
        }
        & label:last-child {
          width: 48%;
          @include response(sm) {
            width: 100%;
          }
        }

        .autocomplete-input {
          border-bottom: none !important;
          input {
            background: inherit;
            outline: none !important;
            //border: none !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 20px !important;
            min-height: 28px !important;
            max-height: 52px !important;
          }
        }
      }
    }
  }
  &-payment {
    padding: 45px 0;
    border-bottom: 1px solid rgba(57, 57, 57, 0.08);

    &-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 305px;
      height: 190px;
      background: #ffffff;
      border-radius: 20px;
      padding: 29px 24px 32px 36px;

      &-mastercard {
        display: flex;
        justify-content: space-between;
        //align-items: start;
      }

      &-input {
        border: none;
        outline: none;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  &-address {
    padding: 45px 0;
    border-bottom: 1px solid rgba(57, 57, 57, 0.08);
  }
  &-email {
    padding: 45px 0;
    border-bottom: 1px solid rgba(57, 57, 57, 0.08);
  }
  &-password {
    padding: 45px 0;

    &-header {
      margin-bottom: 30px;
    }

    &-change {
      display: flex;
      gap: 4%;
      @include response(md) {
        flex-direction: column;
        gap: 17px;
      }
      label {
        width: 100%;
      }
    }
  }
}

.button-save {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 440px;
  height: 40px;
  background: #aac4f9;
  border-radius: 58px;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 18px;
  //gap: 10px;
  margin: 0 auto;
  outline: none;
  border: none;
  cursor: pointer;
  @include response(sm) {
    width: 250px;
  }
  img {
    position: absolute;
    right: 15px;
  }
}
