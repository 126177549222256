@import "src/style/media.scss";

.slide-radiobuttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30rem;
  justify-content: space-evenly;
}

.insomnia-container {
  @include response(md) {
    margin: 15% 0% 15% 0%;
  }
}