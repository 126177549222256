.insomnia {
  padding-bottom: 50px;

  .slide-radiobuttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
}
