@import "src/style/media";

.thank-you {
  background: #f7f9fe;
  height: 100vh;
  padding-bottom: 40px;
  width: 100%;

  @include response(sm) {
    padding-bottom: 0;
  }

  .content-wrapper {
    margin: 0 auto;
    width: 900px;
    //height: calc(100vh - 7vh);
    background: #f7f9fe;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include response(lg) {
      margin: 0;
      width: 100%;
      padding: 0 30px;
    }
    @include response(md) {
      margin-top: 40px;
    }

    .thank-you-title {
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #393939;
      margin-bottom: 26px;
    }
    .thank-you-subtitle {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #393939;
      margin-bottom: 20px;
    }
    .thank-you-description {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #393939;
      margin-bottom: 47px;
    }
    .group-travel-description {
      text-align: center;
    }
    @include response(sm) {
      .stepper-block.adaptive {
        display: flex;
        flex-direction: column;
        .stepper-block-wrapper {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          .stepper-button.progress {
            width: 100%;
          }
        }
      }
    }
    .stepper {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 30px;
      &:before {
        position: absolute;
        content: "";
        border-top: 1px solid #aac4f9;
        width: calc(100% - 230px);
        left: 112px;
        top: 40px;
        margin: 0 auto;
        @include response(sm) {
          display: none;
        }
      }
      @include response(sm) {
        flex-direction: column;
        justify-content: flex-start;
      }

      &-block {
        background-color: #ffffff;
        width: 280px;
        height: 280px;
        border-radius: 20px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        @include response(lg) {
          width: 230px;
        }
        @include response(md) {
          width: 180px;
        }
        @include response(sm) {
          width: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: auto;
          padding: 10px;
          margin-bottom: 10px;
        }
        @include response(sm) {
          &:first-child {
            .stepper-number-circle {
              position: relative;
              &::before {
                content: "";
                border-left: 1px solid #aac4f9;
                height: 120px;
                position: absolute;
                bottom: -120px;
              }
            }
          }
        }

        .stepper-number-circle {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eef3fe;
          width: 56px;
          height: 56px;
          border: 1px solid #aac4f9;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 1;
          position: relative;
        }
        .stepper-number-circle.progress {
          background: inherit;
        }
        .stepper-title {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 42px;
        }
        .stepper-status-circle {
          width: 36px;
          height: 36px;
          background: #319499;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .stepper-status-circle.progress {
          background: #ffffff;
          border: 1px solid #aac4f9;
        }
        .stepper-button {
          outline: none;
          border: 1px solid rgba(49, 148, 153, 0.2);
          border-radius: 32.3944px;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 32px;
          color: #319499;
          background: inherit;
          padding: 0px 30px;
          @include response(sm) {
            display: none;
          }
        }
        .stepper-button.progress {
          background: rgba(49, 148, 153, 0.2);
          @include response(sm) {
            display: block;
            width: 90%;
            margin-top: 15px;
          }
        }
      }
    }

    .go-to-dashboard {
      margin-bottom: 30px;
      padding: 24px;
      width: 100%;
      // height: 300px;
      background-color: #ffffff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      @include response(sm) {
        height: auto;
        width: 100vw;
        border-radius: 0;
      }

      .group-travel-notice {
        padding: 20px;
      }

      .support {
        display: flex;
        align-items: center;
        margin: 20px 0 60px 0;
        @include response(sm) {
          flex-direction: column;
          height: auto;
        }

        &-block {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include response(sm) {
            width: 100%;
            padding: 20px 0;
          }
          p {
            text-align: center;
            &:first-child {
              margin-bottom: 20px;
            }
          }
          &:first-child {
            padding-right: 40px;
            padding-left: 40px;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            a {
              //text-decoration: none;
              font-weight: bold;
              font-size: 20px;
              line-height: 36px;
              text-align: center;
              color: #393939;
            }
          }
          &:last-child {
            border-left: 1px solid #393939;
            padding-left: 40px;
            padding-right: 40px;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            text-align: center;
            color: #393939;
            @include response(sm) {
              border-left: none;
              border-top: 1px solid #393939;
            }
          }
        }
      }

      .support-button {
        background: #aac4f9;
        border-radius: 58px;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 1;
        outline: none;
        border: none;
        padding: 16px 48px;
        cursor: pointer;
      }
    }
  }
}

.thank-you {
  .thank-you-title,
  .thank-you-subtitle {
    letter-spacing: $font-letter-spacing-lg;
  }

  .stepper-title {
    letter-spacing: $font-letter-spacing-lg;
  }

  .go-to-dashboard {
    h2 {
      letter-spacing: $font-letter-spacing-lg;
    }
  }
}
