@import "src/style/media.scss";

.rw-modal-upload-photo {
  padding: 16px;
  width: 100%;
  height: auto;

  @media (max-width: 768px) and (orientation: landscape) {
    padding: 24px;
  }

  @include media-breakpoint(md) {
    padding: 80px 56px;
  }

  &-close-button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 16px;
    right: 16px;

    cursor: pointer;
    width: 40px;
    height: 40px;

    @include media-breakpoint(md) {
      top: 24px;
      right: 24px;
    }
  }

  &-container {
    display: grid;
    background: #f7f8f9;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
    gap: 2px;
    text-align: center;

    @include media-breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }

    .rw-modal-item-left,
    .rw-modal-item-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      padding: 16px 0;

      @include media-breakpoint(md) {
        padding: 24px 0;
      }

      img {
        width: 80px;
        height: 80px;

        @include media-breakpoint(md) {
          width: auto;
          height: auto;
        }
      }

      p {
        margin-bottom: 16px;

        @include media-breakpoint(md) {
          margin-bottom: 40px;
        }
      }
    }

    .rw-modal-item-left {
      @include media-breakpoint(md) {
        padding: 0 32px 0 0;
      }

      @media (max-width: 768px) and (orientation: landscape) {
        padding: 0 16px 0 0;
      }
    }

    .rw-modal-item-right {
      @include media-breakpoint(md) {
        padding: 0 0 0 32px;
      }
      @media (max-width: 768px) and (orientation: landscape) {
        padding: 0 0 0 16px;
      }
    }
  }
}

.modal-button {
  -webkit-text-fill-color: black;
  border: 1px solid #aac4f9;
  width: 240px;
  height: 40px;
  background: none;
  border-radius: 58px;
  cursor: pointer;
  font-size: 20px;

  .file-upload {
    cursor: pointer;
  }

  &:hover {
    background: #aac4f9;
  }
}
