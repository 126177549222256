@import "src/style/media.scss";
.checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkbox-default,
.checkbox-checked {
  width: 630px;
  height: 90px;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
}

.checkbox-checked {
  border: 2px solid #aac4f9;
}

.checkbox-variant {
  margin-left: 2%;
  width: 100%;
}

.items {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  @include response(md) {
    height: auto;
  }
}

.custom-checkbox-input {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

.checkmark-primary {
  width: 32px;
  height: 32px;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  background: white url("./checkmark.svg") center/0% no-repeat;
  transition: background-size 0.1s ease;
}

.custom-checkbox .checkbox-input:checked + .checkmark-primary {
  background: #aac4f9 url("./checkmark.svg") center/65% no-repeat;
  border: 2px solid #aac4f9;
  @include response(md) {
    background: #aac4f9 url("./checkmark.svg") center/auto no-repeat;
  }
}

.custom-checkbox-input .checkbox-input {
  display: none;
}

.short-button {
  height: 38px;
  width: 38px;
  background-color: #aac4f9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include response(sm) {
    width: 65px;
  }
}
