.disable-input {
  display: flex;
  flex-direction: column;
  * {
    box-sizing: border-box;
  }

  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }
  &-change {
    outline: none;
    background: inherit;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    min-height: 28px;
  }
}

textarea {
  resize: none;
  overflow:hidden
}
