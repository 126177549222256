.card-wrapper {
  width: 268px;
}

.card {
  border-radius: 20px;
  background-color: var(--Neutral-Color-Neutral-Lightest, #e0e0e0);
  display: flex;
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 31px;
  padding-bottom: 31px;
  flex-direction: column;
  align-items: stretch;

  .header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;
  }

  .chip-illustration {
    position: relative;
    width: 40px;
  }

  .brand-logo {
    align-self: start;
    position: relative;
    width: 45px;
  }

  .footer {
    color: #393939;
    font-family:
      "Gustavo",
      -apple-system,
      Roboto,
      Helvetica,
      sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin-top: 58px;
    white-space: nowrap;
  }
}
