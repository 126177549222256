@import "src/style/media.scss";

.message {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 1%;

  .message-wrapper {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: column;
    width: 95%;

    p {
      font-family: "Gustavo-Regular";
      font-size: small;
    }

    .message-text {
      background: #ffffff;
      border-radius: 20px 20px 20px 0;
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      line-height: 1.5em;
      font-size: medium;
      color: #393939;
      white-space: pre-line;

      .download {
        color: blue;
        cursor: pointer;
      }
    }

    .message-time {
      display: flex;
      justify-content: flex-start;
      font-size: small;
      .time {
        color: rgba(57, 57, 57, 0.6);
      }
    }
  }
}

.my-message {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;

  .message-wrapper {
    .message-text {
      background: rgba(170, 196, 249, 0.12);
      border-radius: 20px 20px 0 20px;
      padding: 15px 20px;
    }

    .message-time {
      display: flex;
      justify-content: flex-end;
      font-size: small;
    }
  }
}
