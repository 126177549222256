@import "src/style/media.scss";
.SecurityCode-page {
  display: flex;
  width: 100%;
  background: #f7f9fe;
  @include response(md) {
    flex-direction: column;
    height: 100vh;
  }
}

.rw-legacy-2-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(170, 196, 249, 0.03);
  animation: 1s transition;
  @include response(md) {
    width: 100vw !important;
    height: 100% !important;
  }
  @keyframes transition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .SecurityCode-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 0;

    .back-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 4.5rem;
      margin-left: 2.5%;
      cursor: pointer;

      img {
        height: 15px;
      }

      span {
        @include response(sm) {
          display: none;
        }
      }
    }

    .runway-logo {
      img {
        width: 100px;
        height: 40px;
      }
    }

    .help-button {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 8em;
      margin-right: 2%;
      cursor: pointer;
      font-size: 1.1rem;
    }
  }

  .SecurityCode-container {
    width: 540px;
    height: 100%;
    @include response(sm) {
      width: 350px;
      margin-left: 0;
      padding: 10px;
    }

    .SecurityCode-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 55px;
      @include response(md) {
        width: 100%;
        margin-left: 0;
        padding: 10px;
      }
      .SecurityCode {
        width: 100%;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #393939;
        margin-top: 31px;
        @include response(md) {
          font-size: 24px;
        }
      }

      .SecurityCode-icon {
        width: 160px;
        height: 160px;
        padding: 38px;
        border-radius: 100%;
        background: #aac4f957;
      }
      .SecurityCode-form {
        display: flex;
        flex-direction: column;
        width: 80%;
        gap: 16px;
        justify-content: space-evenly;
        margin-top: 80px;
        @include response(md) {
          gap: 20px;
        }
        .form {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          background: #f7f9fe;
          justify-content: space-evenly;
          gap: 60px;
          @include response(md) {
            width: 100%;
          }
          .button-div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 5px;
            @include response(md) {
              margin-top: 1rem;
              width: 100%;
            }
          }
          .SecurityCode-img {
            top: 10px;
            right: 10px;
            position: absolute;
          }

          .SecurityCodeSend {
            border-bottom: 1px dashed #393939;
            cursor: pointer;
            margin-right: 10px;
          }

          .submit-button {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            background: #aac4f9;
            border-radius: 58px;
            border: none;
            font-family: "Gustavo";
            align-items: center;
            padding-right: 21px;
            width: 100%;
            height: 3rem;
            font-size: 1.5rem;
            color: #393939;
            transition: 0.5s;
            &:hover {
              transform: scale(1.03);
            }
          }
          .SecurityCode-input {
            width: 100%;
            height: 3.5rem;
            border-bottom: 1px solid black;
            font-family: "Gustavo";
            font-size: 1.5rem;
            input {
              outline: none;
              height: 100%;
              width: 100%;
              font-family: "Gustavo";
              font-size: 1.5rem;
              border: none;
              background: #f7f9fe;
            }
            @include response(md) {
              width: 100%;
              font-size: 20px;
            }
          }
          .SecurityCode-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            .show {
              cursor: pointer;
            }
          }
          input::placeholder {
            color: rgba(57, 57, 57, 0.5);
          }
        }
      }
    }
  }
}

.error-message-code {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .message-content-login {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #eb5757;
  }
}
