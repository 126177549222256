@import "src/style/media.scss";

.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .slide-title {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1%;
    @include response(md) {
      font-size: 15px;
    }
  }

  .slide-question {
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 930px;
    text-align: center;
    @include response(md) {
      font-size: 24px;
      padding: 10px;
      margin-bottom: 20px;
    }
  }

  .slide-choice {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 24px;
    margin: 16px;
    text-align: center;

    @include media-breakpoint(md) {
      margin-bottom: 32px;
    }
  }

  .slide-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }
}
