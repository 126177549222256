@import "src/style/media";
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
}
input {
  font-family: "Gustavo";
  &::placeholder {
    font-family: "Gustavo" !important;
  }
}

.form-title {
  @media (max-width: 576px) {
    padding: 17px;
  }
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -ms-flex-align: center;
  margin-bottom: 10px;
  margin-right: 20px;

  .cvc {
    display: flex;
    width: 100%;
    border-bottom: 1px solid black;
  }
  .cvc-input {
    border-bottom: none;
    font-family: "Gustavo";
    &::placeholder {
      font-family: "Gustavo" !important;
    }
  }

  .payment-input {
    width: 100%;
    outline: none;
    border: none;
    color: black;
    font-weight: 500;
    font-family: "Gustavo";
    font-size: 20px;
    border-bottom: 1px solid black;
    background: inherit;
    padding: 5px;
    &::placeholder {
      color: rgba(57, 57, 57, 0.3);
      font-family: "Gustavo";
    }
  }

  .MuiAutocomplete-root {
    width: 100% !important;
    @include response(md) {
      width: 100% !important;
    }
  }
  .autocomplete-input {
    width: 100% !important;
    & input {
      height: auto !important;
      width: 100% !important;
      margin-right: 0 !important;
      background: inherit !important;
      border: none !important;
      border-bottom: 1px solid rgb(57, 57, 57) !important;
      padding: 5px !important;
      border-image: initial !important;
      outline: none !important;
      font-size: 20px !important;
    }
  }
}
.label-input {
  display: block;
  font-family: "Gustavo";
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-number {
  border-bottom: 1px solid black;
  .StripeElement {
    border-bottom: none;
  }
}

.card-data-cvc {
  width: 50%;
  flex-direction: column;
}

.StripeElement--webkit-autofill {
  //background: transparent !important;
  //border-top: 1px solid #819efc;
}

.StripeElement {
  width: 100%;
  background: inherit;
  padding: 11px 15px 11px 0;
  border-radius: 0;
  border-bottom: 1px solid black;
  box-shadow: none;
}

.cards-svg {
  height: 100%;
  display: flex;
  gap: 10px;
  padding: 12px 8px 12px 8px;
}

.error-message-payment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  min-height: 20px;
  margin-bottom: 15px;

  &.nonactive {
    display: hidden;
  }

  .message-content {
    text-align: left;
    color: #eb5757;
    width: 100%;

    @include response(md) {
      width: 70%;
    }
  }
}

.span-loader {
  text-align: center;
  width: 100%;
}

.loader-img {
  width: 110px;
  height: 110px;
}

.checkout-email-label {
  width: 100%;
  font-weight: bold;
  padding-left: 4px;
  display: block;
  font-size: 12px;
}

.FormColumn {
  display: flex;
  width: 100%;
  padding: 0;
}
