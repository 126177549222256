.summary {
  margin-bottom: 10px;
  .row {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: repeat(1, 2fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    .col1-title {
      grid-area: 1 / 1 / 2 / 2;
    }
    .col2-title {
      grid-area: 1 / 2 / 2 / 3;
    }
    .title {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .total-amount {
    margin-top: 10px;
    border-top: 1px solid rgba(57, 57, 57, 0.08);
  }
}
