@import "src/style/media";

.pharmacy-find {
  @include response(sm) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &-container {
    width: 630px;
    margin: 0 auto;

    @include response(sm) {
      width: 100%;
    }

    .pharmacy-find-title {
      font-family: Gustavo;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #393939;
      margin: 35px 0;
    }
    .pharmacy-find-subtitle {
      font-family: Gustavo;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #393939;
      margin-bottom: 45px;
    }
    .search-title {
      display: block;
      font-family: Gustavo;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #393939;
      margin-bottom: 25px;
    }
    .pharmacy-find-headline {
      font-family: Gustavo;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #393939;
      margin-bottom: 15px;
    }
    .pharmacy-find-checkouts {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 50px;

    @include response(sm) {
      width: 100%;
      flex-direction: column;
      padding-left: 15px;
      margin-bottom: 20px;
    }

      .checkout {
        display: flex;
        align-items: center;
        gap: 10px;

      @include response(sm) {
        padding-bottom: 15px;
      }
      }
    }
    .checkout-find {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid #bdbdbd;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .status {
        background: #aac4f9;
        border-radius: 50%;
        width: 22px;
        height: 22px;
      }
    }
    .find-input.name {
      width: 100%;
      margin-bottom: 50px;

      @include response(sm) {
        width: 95%;
        margin: 0 auto;
      }

      input {
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid #393939;
        font-family: Gustavo;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 18px;
        color: #393939;
        padding: 10px 0;
        &::placeholder {
          opacity: 0.5;
        }
      }
    }
    .find-input.zip {
      margin-bottom: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @include response(sm) {
        width: 90%;
        margin: 0 auto;
      }
      input {
        font-family: Gustavo;
        width: 210px;
        border: none;
        border-bottom: 1px solid #393939;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 18px;
        color: #393939;
        padding: 10px 0;
        &::placeholder {
          opacity: 0.5;
        }

        @include response(sm) {
          width: 100%;
        }
      }
    }
    .pharmacy-find-button-wrapper {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .pharmacy-find-button {
        position: relative;
        outline: none;
        border: none;
        background: #aac4f9;
        border-radius: 58px;
        width: 80%;
        height: 40px;
        padding: 8px 0;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 18px;
        align-self: center;

        @include response(sm) {
          width: 90%;
          min-width: 315px;
        }
        img {
          position: absolute;
          right: 13px;
          top: 13px;
        }
      }
    }
  }
}

.loading-container {
  margin: 40px 0 40px 0;
}

.error-message-pharmacy-find {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20px;
  width: 100%;
  font-size: 15px;
  padding: 10px 0 15px 0;

  &.nonactive {
    display: hidden;
  }

  .message-content {
    text-align: center;
    color: #eb5757;
    width: 100%;

    @include response(md) {
      width: 70%;
    }
  }
}