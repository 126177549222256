@import "src/style/media";

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-pharmacy-advice {
  text-align: end;
  @include response(md) {
    text-align: left;
  }
}

.tracking-number {
  text-transform: uppercase;
  text-align: end;
  @include response(md) {
    text-align: left;
    font-size: small;
  }
}
.case-updated-date {
  font-size: 12px;
  float: right;
  color: gray;
}

.express-menu {
  display: flex;
  justify-content: space-evenly;
  @include response(lg) {
    display: inline;
  }
}

.referral-form-link {
  text-decoration: underline;
  color: #3297ff;
  cursor: pointer;
}

.alert-banner-container {
  margin-bottom: 5px;
}

.order-items-container {
  padding: 8px 0 2px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
}

.case-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  &-button {
    cursor: pointer;
    width: 280px;
    background-color: white;
    border-radius: 12px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding-top: 17px;
    @include response(lg) {
      width: 100%;
    }
  }
  &-subtitle {
    align-items: center;
    display: flex;
    gap: 8px;
  }
  &-execute-btns {
    display: flex;
    gap: 8px;
    justify-content: center;
    @include response(md) {
      flex-direction: column-reverse;
    }
  }
}
