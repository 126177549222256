@import "src/style/media.scss";

.rw-box-error-message {
  color: #eb5757;
  text-align: center;
  margin-top: 24px;

  @include media-breakpoint(md) {
    margin-top: 24px;
  }
}
