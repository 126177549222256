.checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkbox-default,
.checkbox-checked {
  width: 630px;
  height: 90px;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
}

.items {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.checkbox-checked {
  border: 2px solid #aac4f9;
}

.checkbox-variant {
  width: 35rem;
}

.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 2%;
  width: 100%;
  z-index: 100000;
}

.checkmark-primary {
  width: 32px;
  height: 32px;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  background: white url("./checkmark.svg") center/0% no-repeat;
  transition: background-size 0.1s ease;
}

.custom-checkbox .checkbox-input:checked + .checkmark-primary {
  background: #aac4f9 url("./checkmark.svg") center/65% no-repeat;
  border: 2px solid #aac4f9;
}

.checkbox-input:disabled + .checkmark-primary {
  background: rgba(#bdbdbd, 0.2);
}

.custom-checkbox .checkbox-input {
  display: none;
}

@media (max-width: 600px) {
  .checkmark-primary {
    width: 50px !important;
  }
}
