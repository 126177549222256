.done-btn {
  max-width: 100% !important;
  width: 40% !important;
}
.reward-view-actions-wrapper {
  display: flex;
  padding: 2%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.referral-link-text-field {
  width: 80% !important;
}
