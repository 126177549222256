.pharmacy-alert {
  width: 100%;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img{
      transition: 1s;
    }
    .error{
      transform: rotate(45deg);
      transition: 1s;
    }
    p{
      margin-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
    }
  }
}