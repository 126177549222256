// Globals
$font-letter-spacing-md: 0.05rem;
$font-letter-spacing-lg: 0.0625rem;

// Mobile first breakpoints
// parameters: sm, md, lg, xl, xxl
@mixin media-breakpoint($value) {
  // Small devices (landscape phones, 576px and up)
  @if ($value == sm) {
    @media (min-width: 576px) {
      @content;
    }
  }

  // Medium devices (tablets, 870px and up)
  @if ($value == md) {
    @media (min-width: 870px) {
      @content;
    }
  }

  // Large devices (desktops, 992px and up)
  @if ($value == lg) {
    @media (min-width: 992px) {
      @content;
    }
  }

  // X-Large devices (large desktops, 1200px and up)
  @if ($value == xl) {
    @media (min-width: 1200px) {
      @content;
    }
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @if ($value == xxl) {
    @media (min-width: 1400px) {
      @content;
    }
  }
}

// Legacy breakpoints
// Consider using mobile first approach
@mixin response($breakpoint) {
  @if ($breakpoint == xl-lg) {
    @media (max-width: 2000px) {
      @content;
    }
  }
  @if ($breakpoint == xl-md) {
    @media (max-width: 1300px) {
      @content;
    }
  }
  @if ($breakpoint == xl) {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @if ($breakpoint == lg) {
    @media (max-width: 1000px) {
      @content;
    }
  }
  @if ($breakpoint == md) {
    @media (max-width: 870px) {
      @content;
    }
  }
  @if ($breakpoint == sm) {
    @media (max-width: 560px) {
      @content;
    }
  }
}
