@import "src/style/media.scss";
.choice-buttons,
.gender-buttons {
  button {
    outline: none;
    transition: all 200ms ease-in;

    box-shadow: none;
    text-decoration: none;
    user-select: none;
    outline: none;

    &:hover {
      background: #95b6fb;
      border: 2px solid #95b6fb;
    }

    &:active {
      background: #7f9fe4;
      border: 2px solid #7f9fe4;
    }

    &:focus {
      outline: none;
    }
  }
}

.destination-buttons {
  display: flex;

  @include response(md) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
    .choice-first {
      border-radius: 20px 20px 0 0;
      margin-bottom: -2px;
      width: 90%;
    }
    .choice-second {
      border-radius: 0 0 20px 20px;
      border-left: 2px solid #e0e0e0;
      width: 90%;
    }
  }
}

.choice-first,
.choice-second {
  width: 265px;
  height: 90px;
  border: 2px solid #e0e0e0;
  background: white;
  border-radius: 20px 0px 0px 20px;
  border-right: 0.5px solid #e0e0e0;
  -webkit-text-fill-color: black;
  font-family: Gustavo;
  font-size: 20px;

  &.active {
    background: #7f9fe4;
    border: 2px solid #7f9fe4;

    box-shadow: none;
    text-decoration: none;
    user-select: none;
    white-space: nowrap;
    outline: none;
  }
}

.choice-second {
  border: 2px solid #e0e0e0;
  border-radius: 0px 20px 20px 0px;
  border-left: 0.5px solid #e0e0e0;
}

.choice-first:hover,
.choice-second:hover {
  cursor: pointer;
}

.choice-first:active,
.choice-second:active {
  background: #aac4f9;
  border: none;
}

@media (max-width: 768px) {
  .choice-buttons {
    flex-direction: row;
  }
  .choice-first,
  .choice-second {
    width: 170px;
    height: 100px;
    border-right: 2px solid #e0e0e0;
  }
}
