.centered-text {
  display: flex;
  justify-content: left;
  align-items: center;
}

.info {
  font-style: italic;
  font-size: 14px;
  color: gray;
}
