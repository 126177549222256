@import "src/style/media.scss";

.warning {
  // margin-top: 16px;
  text-align: center;
  font-size: 16px;
  color: #eb5757;
  width: 100%;
  @include response(md) {
    top: 100%;
    left: 0%;
    text-align: center;
    width: 100%;
  }
}
