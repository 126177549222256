.loader {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
  gap: 30px;
}
