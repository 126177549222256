@import "src/style/media.scss";

.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  box-sizing: border-box;

  .notice-container {
    padding: 0px 10%;
  }

  .notice-box {
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
  }

  &-menu {
    width: 30vw;
    background: #ffffff;

    @include response(md) {
      z-index: 100;
      width: 100%;
      position: absolute;
      top: 0px;
      left: -100%;
      transition: all 0.5s;
    }
  }
  &-content {
    width: 70vw;
    min-height: 100vh;
    background: rgba(170, 196, 249, 0.12);
    overflow-y: auto;

    @include response(md) {
      width: 100vw;
    }

    &-header {
      display: flex;
      justify-content: flex-end;
      gap: 40px;
      align-items: center;
      padding: 15px 50px;

      @include response(md) {
        justify-content: space-between;
        padding: 10px 15px;
      }

      .mobile-menu {
        display: none;
        @include response(md) {
          display: block;
        }
      }
      .mobile-logo {
        display: none;
        @include response(md) {
          display: block;
        }
      }

      &-icons {
        display: flex;
        align-items: center;
        gap: 15px;

        .annunciation {
          cursor: pointer;
          position: relative;

          .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #319499;
            position: absolute;
            top: -7px;
            right: -3px;
          }

          img {
            width: 20px;
          }
        }

        .help-button {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 15px;

          img {
            width: 24px;
          }

          span {
            @include response(md) {
              display: none;
            }
          }
        }
      }
    }
  }
  .notification {
    @include response(md) {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: green;
      border-radius: 50%;
      position: absolute;
      transform: translate(50%, -50%);
      top: 21px;
      left: 27px;
    }
  }
}
