@import "src/style/media.scss";

.help-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: fit-content;

  cursor: pointer;

  @include media-breakpoint(md) {
    margin-left: 0.25rem;
  }

  img {
    width: 28px;
    height: auto;

    @include media-breakpoint(md) {
      width: 31px;
    }
  }

  span {
    display: none;
    font-size: 14px;
    @include media-breakpoint(md) {
      display: block;
    }
  }
}

.wrapper-help-modal {
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(35, 60, 146, 0.16);
  right: 1%;
  top: 85px;
  width: 315px;
  height: 130px;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);

  @include response(md) {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    width: 98%;
    height: 100%;
    border-radius: 0;
    background-color: white;
  }

  .items-container {
    margin: 1em;
  }

  .top-bot {
    position: absolute;
    top: -20px;
    right: 32%;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }

  .top-bot-border {
    top: -18px;
    border-color: transparent transparent #fff transparent;
  }

  .modal-close-button:hover {
    cursor: pointer;
  }

  .first-item {
    margin: 1em 25px;
    display: flex;
    justify-content: space-between;

    @include response(md) {
      margin: 2em 25px;
      width: 95%;
    }
  }

  .first-item p {
    font-weight: 700;
    font-size: 18px;
  }

  .second-item {
    margin-left: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    @include response(md) {
      font-size: 16px;
    }
  }

  .second-item img {
    margin-top: 0.3em;
  }

  .second-item .email-message-text {
    margin-left: 12px;
  }

  .second-item .email-message-text a:nth-child(2) {
    font-weight: 900;
    color: black;
  }
}
