@import "src/style/media.scss";

.main-div {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .first-things {
    font-size: 26px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
  }

  .first-links {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 24px;
  }
}
.Toastify__toast {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(35, 60, 146, 0.16);
  border-radius: 8;
}

// .Toastify__toast-container--top-center {
//   width: 535px !important;
// }

// .Toastify__close-button {
//   width: 40px;
//   height: 40px;
//   opacity: 0.1 !important;
//   border: 1px solid #828282 !important;
//   box-sizing: border-box;
//   border-radius: 58px !important;
//   padding: 13px;
// }

// .Toastify__close-button > svg {
//   width: 11.54px;
//   height: 15.18px;
// }

.question-container,
.conditions-container,
.sleep-container,
.allergies-container,
.vaccines-container {
  outline: none;
}

.slide-question {
  @include media-breakpoint(md) {
    margin-top: 0.3em;
  }
}
