@import "src/style/media.scss";

.locked-chat-message {
  padding: 5%;
}

.messages {
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  &-users {
    min-width: 250px;
    width: 35%;
    background: #ffffff;
    border-radius: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &-none {
      @include response(md) {
        display: none;
      }
    }

    @include response(md) {
      width: 100%;
      height: 100%;
      background: inherit;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      img {
        cursor: pointer;
      }
    }

    .supports {
      border-bottom: 1px solid rgba(57, 57, 57, 0.08);
    }
  }
  &-chats {
    height: 600px;
    @media (min-width: 576px) {
      height: 600px;
      width: 100%;
      border: solid;
      border-radius: 12px;
      border-style: hidden;
      padding: 20px;
      background: #f4f6f8;
    }

    .mobile {
      display: flex;
      align-items: center;
      gap: 5px;
      &-arrow {
        display: none;
        cursor: pointer;

        @include response(md) {
          display: block;
        }
      }
    }

    @include response(md) {
      height: calc(100vh - 78px);
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 15px;
    }

    &-header {
      height: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        span {
          display: block;
        }
        .name {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          display: flex;
          gap: 5px;
          align-items: center;
        }
        .status {
          font-weight: normal;
          font-size: 12px;
          @include response(md) {
            margin-left: 20px;
          }
        }
        .circle-status {
          display: none;
          margin-right: 5px;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background: #319499;
          @include response(md) {
            display: block;
          }
        }
      }

      &-right {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 71px;
        background: rgba(49, 148, 153, 0.2);
        border-radius: 20px;
        @include response(md) {
          display: none;
        }

        .circle-status {
          margin-right: 5px;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          background: #319499;
        }
      }
    }
    &-main {
      margin-bottom: 10px;
      overflow-y: auto;
      padding: 10px !important;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    &-wrapper {
      position: relative;
      max-height: 65vh;
      height: 65vh;
      overflow-y: auto;
      margin: 2%;
      background: #f6fbff;
      border-radius: 10px;
      &-loader {
        @extend .messages-chats-wrapper;
        text-align: center;
      }
    }
    &-file {
      width: 100%;
    }
    &-footer {
      display: flex;
      flex-direction: column;
      padding: 12px;
      &-buttons {
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
}

.download-file-message {
  input {
    display: none;
  }
  img {
    cursor: pointer;
  }
}
