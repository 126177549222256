@import "src/style/media.scss";

.rw-legacy-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(170, 196, 249, 0.03);
  animation: 1s transition;
  @include response(md) {
    width: 100vw !important;
    height: 100% !important;
  }
  @keyframes transition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .SetNewPassword-header {
    width: 100%;
    margin-top: 1.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    .back-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 4.5rem;
      margin-left: 2.5%;
      cursor: pointer;
      img {
        height: 15px;
      }

      span {
        @include response(sm) {
          display: none;
        }
      }
    }

    .help-button {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 8em;
      margin-right: 2%;
      cursor: pointer;
      font-size: 1.1rem;
    }
  }

  .SetNewPassword-container {
    width: 440px;
    height: 100%;
    @include response(sm) {
      width: 350px;
      margin-left: 0;
      padding: 10px;
    }

    .SetNewPassword-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 120px;
      @include response(md) {
        width: 100%;
        margin-left: 0;
        padding: 10px;
      }
      .SetNewPassword {
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #393939;
        @include response(md) {
          font-size: 24px;
        }
      }

      .SetNewPassword-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-evenly;

        @include response(md) {
          gap: 20px;
        }
        p {
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          color: #393939;
          margin-bottom: 55px;
        }
        .form {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          background: #f7f9fe;
          justify-content: space-evenly;
          margin-top: 60px;
          @include response(md) {
            width: 100%;
          }
          .button-div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 5px;
            margin-top: 60px;
            @include response(md) {
              margin-top: 1rem;
              width: 100%;
            }
          }

          .submit-button {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            background: #aac4f9;
            border-radius: 58px;
            border: none;
            font-family: "Gustavo";
            align-items: center;
            padding-right: 21px;
            width: 100%;
            height: 3rem;
            font-size: 1.5rem;
            color: #393939;
            transition: 0.5s;
            &:hover {
              transform: scale(1.03);
            }
          }
          .SetNewPassword-input {
            width: 100%;
            height: 3.5rem;
            border-bottom: 1px solid black;
            font-family: "Gustavo";
            font-size: 1.5rem;
            input {
              outline: none;
              height: 100%;
              width: 100%;
              font-family: "Gustavo";
              font-size: 1.5rem;
              border: none;
              background: #f7f9fe;
            }
            @include response(md) {
              width: 100%;
              font-size: 20px;
            }
          }
          .SetNewPassword-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            .show {
              cursor: pointer;
            }
          }
          .SetNewPassword-ul {
            li {
              padding: 0;
              line-height: 21px;
            }
          }

          .info {
            position: absolute;
            width: 438px;
            height: 199px;
            background-color: #ffffff;
            box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
            font-size: 16px;
            top: 64px;
            border-radius: 20px;
            padding: 22px 0;
            color: #000000;
            font-family: "Gustavo";
            line-height: 31px;
            z-index: 2;
            &__active {
              border: 2px solid #aac4f9;
            }
            &__inactive {
              border: 2px solid #eb5757;
            }
          }
          .SetNewPassword-info {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 48px;
            width: 28px;
            height: 28px;

            border-radius: 100%;
            padding: 7px;
            img {
              display: block;
            }
            &__active {
              background: #aac4f9;
            }
            &__inactive {
              background: #eb5757;
            }
          }

          input::placeholder {
            color: rgba(57, 57, 57, 0.5);
          }
        }
      }
    }
  }
}

.error-message {
  color: #ff7373;
  font-weight: 100;
}

.mobile-checkboxes {
  display: flex;
  margin: 1rem 0;
}

.terms-label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
