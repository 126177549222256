@font-face {
  font-family: "Gustavo";
  src: local("Gustavo"), url("./fonts/Gustavo/Gustavo-Regular.otf");
  font-weight: 300;
  // This help us avoiding invisible text while loading fonts.
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Gustavo", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.error {
  display: block;
  color: red;
  font-size: 23px;
  margin-top: 10px;
}
