@import "src/style/media.scss";
.choice-buttons {
  display: flex;
  @include response(md) {
    flex-direction: column;
  }
}

.choice-male {
  width: 265px;
  height: 90px;
  border: 2px solid #e0e0e0;
  background: white;
  border-radius: 20px 0px 0px 20px;
  border-right: 0.5px solid #e0e0e0;
  -webkit-text-fill-color: black;
  font-family: Gustavo;
  font-size: 20px;
  @include response(md) {
    border-right: 2px solid #e0e0e0;
  }
}

.choice-female {
  width: 265px;
  height: 90px;
  background: white;
  border: 2px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  border-right: 0.5px solid #e0e0e0;
  -webkit-text-fill-color: black;
  font-size: 20px;
  font-family: Gustavo;
  @include response(md) {
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
  }
}

.choice-other {
  width: 265px;
  height: 90px;
  background: white;
  border: 2px solid #e0e0e0;
  border-radius: 0px 20px 20px 0px;
  border-left: 0.5px solid #e0e0e0;
  -webkit-text-fill-color: black;
  font-size: 20px;
  font-family: Gustavo;
  @include response(md) {
    border-left: 2px solid #e0e0e0;
  }
}

.choice-male:hover,
.choice-female:hover,
.choice-other:hover {
  cursor: pointer;
}

.choice-male:active,
.choice-female:active,
.choice-other:active {
  background: #aac4f9;
  border: none;
}

@media (max-width: 768px) {
  .gender-buttons {
    display: flex;
    flex-direction: row;
  }

  .choice-male {
    border-radius: 20px 20px 0px 0px;
    border-bottom: 0;
  }

  .choice-other {
    border-radius: 0px 0px 20px 20px;
  }
}
