.textfield-password{
  display: flex;
  flex-direction: column;
  *{
    box-sizing: border-box;
  }

  &-title{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
  }
  &-input {
    border-bottom: 1px solid #393939;
    display: flex;
    justify-content: space-between;
    img{
      cursor: pointer;
    }
    input {
      outline: none;
      background: inherit;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 18px;
    }
  }
}