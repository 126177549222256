@import "src/style/media.scss";
.date-inputs {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: space-evenly;
  font-size: 36px;
  margin-bottom: 30px;
}

.short-inputs-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.date-input-map {
  display: flex;
  align-items: center;
}

.warning-banner {
  margin-top: 16px;
}
