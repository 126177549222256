@import "src/style/media.scss";

.button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.next-button {
  -webkit-text-fill-color: black;
  cursor: pointer;
  background-color: #aac4f9;
  color: black;
  border-radius: 58px;
  margin-top: 40px;
  border: none;
  font-family: "Gustavo";
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 30rem;
  height: 3rem;
  font-size: 1.4rem;
  transition: 0.5s;
  position: relative;
  letter-spacing: $font-letter-spacing-md;

  &.disabled {
    -webkit-text-fill-color: unset;
    background-color: #f2f2f2;
    color: #c3c3c3;
  }

  @include response(md) {
    width: 291px;
    font-size: 20px;
    line-height: 18px;
  }
}

.button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include response(md) {
    font-size: 21px;
    width: 97%;
  }
}

.arrow-right-icon {
  position: absolute;
  right: 15px;
  bottom: 15px;

  &.disabled {
    filter: opacity(0.2);
  }
}
