.pharmacy-find-element{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  border: 2px solid #E0E0E0;
  border-radius: 20px;
  padding: 17px 30px;
  .checkbox{
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border: 2px solid #BDBDBD;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .checkbox-status{
      width: 22px;
      height: 22px;
      background: #AAC4F9;
      border-radius: 50%;
    }
  }
  .pharmacy-info{
    h2{
      font-family: Gustavo;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
    }
    p{
      font-family: Gustavo;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: #6C6C6C;
      word-break: break-all;
    }
  }
}