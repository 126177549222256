.state-zip-group {
  display: flex;
  gap: 16px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .zip-input {
    width: 70%;
  }
  .state-select {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4px;
  }
  .state-label {
    margin-bottom: 0px !important;
  }
}
.payment-form-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  button {
    width: 100%;
  }
}
