@import "src/style/media.scss";

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-family: "Gustavo", sans-serif;
  font-size: 24px;
}
.control input {
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 3px;
  left: 0;
  height: 32px;
  width: 32px;
  background: #ffffff;
  border: 2px solid #bdbdbd;
  border-radius: 50%;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #ffffff;
}

.control input:checked ~ .control_indicator {
  background: #ffffff;
  border: 2px solid #aac4f9;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #ffffff;
}
.control input:disabled ~ .control_indicator {
  background: #bdbdbd;
  opacity: 3;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
  background: #aac4f9;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 3px;
  top: 3px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #aac4f9;
  transition: background-color 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: #bdbdbd;
}
.control-radio .control_indicator::before {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  margin-left: -1rem;
  margin-top: -1rem;
  background: #aac4f9;
  border-radius: 50%;
  opacity: 0.4;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0.01;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.01);
  }
  60% {
    transform: scale(1.03);
  }
  100% {
    opacity: 0;
    transform: scale(1.05);
  }
}
.control-radio input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}

.checkbox-form {
  width: 630px;
  height: 90px;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 20px;
  font-family: Gustavo;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
  background-color: #fff;
  text-align: left;

  &.checked {
    border: 2px solid #a9c3f9;
  }
  @include response(md) {
    width: 100%;
    height: auto;
    padding: 15px;
  }
}
.items {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start !important;
  cursor: pointer;
}

.control-area {
  width: 98%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: normal;
}
