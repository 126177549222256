@import "src/style/media.scss";
.pharmacy-page-main {
  margin-bottom: 40px !important;
  margin-top: 0 !important;
}
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;

  .phone-inputs {
    .login-input {
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid black;
      outline: none;
      font-size: 20px;
      font-family: "Gustavo";

      &::placeholder {
        color: rgb(22%, 22%, 22%, 0.3);
      }

      //@include response(md) {
      //  width: 100%;
      //  font-size: 20px;
      //}
    }
  }

  .slide-headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 5rem;

    .slide-header {
      margin-bottom: 20px;

      @include response(md) {
        margin-bottom: 30px;
      }
    }

    .slide-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      text-align: center;
      //height: 10rem;
      //margin-bottom: 80px;

      .slide-name {
        font-weight: bold;
        font-size: 24px;
        margin-top: 30px;
      }

      .slide-descript {
        font-size: 20px;
        width: 75%;
      }
    }

    .street-address-label {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .buts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 30px;
    //height: 15rem;

    .select-pharmacy {
      color: black;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      letter-spacing: $font-letter-spacing-md;
    }
  }

  .inputs {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    justify-content: baseline;
  }

  .home-address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 15rem;
  }
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  height: 20px;

  &.nonactive {
    display: hidden;
  }

  .message-content {
    text-align: center;
    color: #eb5757;
    width: 100%;
    padding: 0 10px 0 10px;

    @include response(md) {
      width: 70%;
    }
  }
}
