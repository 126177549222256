@import "src/style/media";
.cases {
  padding: 80px 10%;

  h2 {
    margin-bottom: 20px;
  }

  @include response(md) {
    padding: 40px 80px;
  }
  @include response(sm) {
    padding: 10px 20px;
  }

  .refresh-button {
    padding: 10px;
  }

  .case-wrapper {
    margin-bottom: 20px;
  }
}
