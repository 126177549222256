@import "src/style/media.scss";

.manager-wrapper {
  @media (max-width: 576px) {
    padding: 9px !important;
  }
  .manager-title {
    @media (max-width: 576px) {
      padding: 17px;
    }
  }
  @media (max-width: 870px) {
    padding: 15px !important;
    box-shadow: none !important;
  }
  &-card-hidden {
    @extend .manager-wrapper;
    padding: 0px !important;
    box-shadow: none !important;
  }
}

.loader-img {
  text-align: center;
}

p::first-letter {
  text-transform: capitalize;
}
h3::first-letter {
  text-transform: capitalize;
}
