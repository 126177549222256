@import "src/style/media.scss";

.slide-title {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1%;
  @include response(md) {
    font-size: 15px;
  }
}

.slide-question {
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  @include response(md) {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

.slide-choice {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @include response(md) {
    margin-bottom: 50px;
  }
}

.slide-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3%;
}

.slide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @include response(md) {
    margin: 0% 4% 0% 5%;
  }
}
