@import "src/style/media.scss";

.error-message-delivery {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  .message-content-delivery {
    text-align: center;
    color: #eb5757;
    width: 100%;
    padding: 0 10px 0 10px;

    @include response(md) {
      width: 70%;
    }
  }
}

.priority-shipping-suggestion {
  text-align: left;
  padding: 40px;
}
