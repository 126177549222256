@import "src/style/media.scss";

// .login-page {
//   display: flex;
//   width: 100%;
//   background: #f7f9fe;

//   @include response(md) {
//     flex-direction: column;
//     height: 100vh;
//   }
// }

.right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: 1s transition;
  margin-top: 48px;

  @include response(md) {
    width: 100vw !important;
    height: 100% !important;
  }

  @keyframes transition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .login-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 0;

    .back-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 4.5rem;
      margin-left: 2.5%;
      cursor: pointer;

      img {
        height: 15px;
      }

      span {
        @include response(sm) {
          display: none;
        }
      }
    }

    .runway-logo {
      img {
        width: 100px;
        height: 40px;
      }
    }

    .help-button {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 8em;
      margin-right: 2%;
      cursor: pointer;
      font-size: 1.1rem;
    }
  }

  .sign-container {
    width: 440px;
    height: 100%;
    @include response(sm) {
      width: 350px;
      margin-left: 0;
      padding: 10px;
    }

    .sign-up-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      @include response(md) {
        width: 100%;
        margin-left: 0;
        padding: 10px;
      }

      .sign-up {
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #393939;
        @include response(md) {
          font-size: 24px;
        }
      }

      .login-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 40px;
        //height: 50%;
        justify-content: space-evenly;
        @include response(md) {
          gap: 20px;
        }

        .form {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          justify-content: space-evenly;
          gap: 30px;
          @include response(md) {
            width: 100%;
          }

          .input-names {
            display: flex;
            justify-content: space-between;
            width: 80%;
            @include response(md) {
              display: flex;
              flex-direction: column;
              width: 100%;
            }
          }

          .agreement {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: -2%;

            .terms,
            .consent {
              display: flex;
              align-items: center;

              b {
                font-weight: bold;
                cursor: pointer;
                text-decoration: none;
                color: black;
              }
            }
          }

          .button-div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 5px;
            margin-top: 24px;

            @include response(md) {
              margin-top: 16px;
              width: 100%;
            }
          }

          .submit-button {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            background: #aac4f9;
            border-radius: 58px;
            border: none;
            font-family: "Gustavo";
            align-items: center;
            padding-right: 21px;
            width: 100%;
            height: 3rem;
            font-size: 1.5rem;
            color: #393939;
            transition: 0.5s;

            &:hover {
              transform: scale(1.03);
            }
          }
          .forgot-password {
            a {
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
              text-align: center;
              text-decoration-line: underline;
              color: #393939;
            }
          }

          .lable-input {
            position: absolute;
            top: -17px;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            color: #393939;
          }

          .login-input-names {
            width: 45%;
            min-width: 10rem;
            height: 3.5rem;
            border: none;
            border-bottom: 1px solid black;
            outline: none;
            font-size: 1.5rem;
            font-family: "Gustavo";
            @include response(md) {
              width: 100%;
              font-size: 20px;
            }
          }

          .login-input {
            width: 100%;
            height: 3.5rem;
            border-bottom: 1px solid black;
            font-family: "Gustavo";
            font-size: 20px;

            input {
              outline: none;
              height: 100%;
              width: 100%;
              font-family: "Gustavo";
              font-size: 20px;
              border: none;
              background: #f7f9fe;
            }

            @include response(md) {
              width: 100%;
              font-size: 20px;
            }
          }
          .login-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            .show {
              cursor: pointer;
            }
          }

          input::placeholder {
            color: rgba(57, 57, 57, 0.5);
          }
        }
      }
    }
  }
}

.mobile-checkboxes {
  display: flex;
  margin: 1rem 0;
}

.error-message-login {
  display: flex;
  align-items: center;
  justify-content: center;

  .message-content-login {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #eb5757;
    margin-top: 18px;
  }
}

.terms-label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
