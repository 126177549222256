.rw-admin-main {
  h1 {
    margin-left: 0.5em;
  }
  section {
    margin: 2em;

    div {
      margin: 1em;
    }
  }

  label {
    margin-right: 0.5em;
  }

  .dates {
    & > input {
      margin-right: 0.5em;
    }
  }

  table {
    border-collapse: collapse;
    .td-pointer {
      cursor: pointer;
    }
  }
  td,
  th {
    border: 1px solid black;
    padding: 10px;
  }

  .noMargin {
    margin-left: 0;
  }

  .nonMaterialAlignment {
    margin-bottom: 2rem;
    vertical-align: bottom;
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
