@import "src/style/media.scss";

.tl-container-vaccines {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  .slide-question-vaccines {
    max-width: 856px;
    font-size: 36px;
    @include response(md) {
      width: 75%;
      font-size: 24px;
    }
  }
  .slide-choice-vaccines {
    font-size: 20px;
    margin: 14px;
    @include response(md) {
      margin: 30px 0;
    }
  }
}

.slide-radiobuttons-vaccines {
  .checkbox-form-vaccines {
    margin-bottom: 20px;
  }
  .button-div-vaccines {
    margin-top: 40px;
  }
}

.slide-checkboxes-vaccines {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 50rem;
}

.vaccines-container {
  @include response(md) {
    margin: 0 0 15% 0;
  }
}
