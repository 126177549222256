@import "src/style/media.scss";
.short-input {
  .custom-input {
    width: 50px;
    margin-right: 0.5rem;
    height: 5rem;
    border: none;
    border-bottom: 1px solid #393939;
    outline: none;
    font-size: 36px;
    font-family: "Gustavo";
    text-align: center;
    background: inherit;

    @include response(md) {
      width: 33px;
      font-size: 17px;
      height: 3rem;
    }
  }

  input:focus {
    border-bottom: 1px solid #aac4f9;
  }

  input:focus::placeholder {
    color: transparent;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    padding-bottom: 3%;
    text-align: center;
    color: rgba(#000000, 0.3);
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  :-moz-placeholder {
    text-align: center;
  }
}
