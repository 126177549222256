@import "src/style/media.scss";

.menu {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;
  width: 100%;

  @include response(md) {
    padding-top: 0;
  }

  .menu-mobile {
    display: none;
    align-items: center;
    gap: 19px;
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 18px;
    cursor: pointer;
    @include response(md) {
      display: flex;
    }
  }
  .logo-desktop {
    @include response(md) {
      display: none;
    }
  }
  &-nav {
    padding: 55px;
    @include response(md) {
      padding: 25px;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    .logo {
      margin-bottom: 100px;
    }
    &-link {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        font-family: "Gustavo-Medium", sans-serif;
        font-weight: 400;
      }
    }

    a {
      text-decoration: none;
      color: black;
    }
  }

  .active {
    background: #eef3fe;
    border-radius: 12px;
  }

  .highlight {
    border: #96b5f9;
    border-width: thin;
    border-style: solid;
    border-radius: 13px;
  }

  .logout {
    padding: 14px 15px 15px 33px;
    cursor: pointer;
    display: flex;
    gap: 15px;
  }

  .notification-dot {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    transform: translate(50%, -50%);
    top: 29px;
    right: 18px;
  }
}
