@import "src/style/media.scss";
.long-input {
  .custom-long-input {
    width: 630px;
    margin-right: 0.5rem;
    height: 4.5rem;
    border: none;
    border-bottom: 1px solid #393939;
    outline: none;
    font-size: 36px;
    font-family: "Gustavo";
    background: inherit;
    // text-align: center;
    @include response(md) {
      width: 100%;
      font-size: 20px;
    }
  }

  input:focus {
    border-bottom: 1px solid #aac4f9;
  }

  input:focus::placeholder {
    color: transparent;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    padding-bottom: 3%;
    // text-align: center;
    color: rgba(#000000, 0.3);
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  :-moz-placeholder {
    // text-align: center;
  }
}
