@import "src/style/media";

.upsell-page-body {
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    border-radius: 20px;
    background-color: white;
    padding: 5%;
    margin: 3%;
    width: 80%;
    @include response(md) {
      width: 100%;
      padding: 4%;
    }
    .btn-container {
      padding-top: 20px;
    }
  }
}
